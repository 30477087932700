<template>
    <div class='createIndex'>
      <CreateAndEdit />
    </div>
  </template>
  
  <script>
  import CreateAndEdit from './components/CreateAndEdit.vue'
  export default {
    name: 'createTeamLedgerIndex',
    components: {
      CreateAndEdit
    },
    data () {
      return {
  
      }
    },
    mounted () {
  
    },
    methods: {
  
    }
  }
  </script>
  <style lang='scss' scoped>
  
  </style>
  